<template>
  <div>
    <div class="title">{{ $t("adnavPage.financingManage") }}</div>
    <el-form v-if="false" class="flex-row" ref="form" :model="form" style="width: 1100px">
      <el-form-item class="flex-row" :label="$t('projectNumber')">
        <el-input class="order-num" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item class="search-date" label="申请时间：">
        <el-col :span="8">
          <el-form-item prop="date1">
            <el-date-picker
              type="date"
              :placeholder="$t('selectDate')"
              v-model="form.date1"
              style="width: 95%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="1">至</el-col>
        <el-col :span="8">
          <el-form-item prop="date2">
            <el-date-picker
              type="date"
              :placeholder="$t('selectDate')"
              v-model="form.date2"
              style="width: 98%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-button class="btn-black" style="margin-bottom: 22px">搜索</el-button>
    </el-form>
    <div class="flex-between">
      <div class="btn-list">
        <el-button
          :disabled="isClick"
          :class="item.select ? 'btn-black' : 'btn-gray'"
          v-for="(item, index) in searchFinac"
          :key="index"
          @click="typeClick(item, index)"
        >{{ $t(item.title) }}</el-button>
      </div>
      <el-button
        class="btn-black"
        @click="$router.push({ path: '/assets-list/assets-finance' })"
        style="margin-bottom: 22px"
      >{{ $t("applyForFinancing") }}</el-button>
    </div>
    <el-table :data="tableData" style="width: 1200px">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column prop="status" :label="$t('currentStage')" width="110" align="center">
        <template v-slot="scope">{{ scope.row.statusText }}</template>
      </el-table-column>
      <el-table-column prop="id" :label="$t('projectNumberAgain')" width="130" align="center"></el-table-column>
      <el-table-column
        prop="receivablesOrder.businessType"
        :label="$t('financingType')"
        width="120"
        align="center"
      >
        <template v-slot="scope">{{ scope.row.receivablesOrder.businessType }}</template>
      </el-table-column>
      <el-table-column
        prop="financingAmount"
        :label="$t('financingAmountAgain')"
        width="170"
        align="center"
      >
        <template v-slot="scope">
          {{scope.row.financingAmount | toThousandFilterTwo}}
        </template>
      </el-table-column>
      <el-table-column prop="borrowingCycle" :label="$t('term')" width="120" align="center"></el-table-column>
      <el-table-column prop="interestRate" :label="$t('interestRate')" width="100" align="center">
        <template
          v-slot="scope"
        >{{ scope.row.interestRate ? scope.row.interestRate + '%' : '0%' }}</template>
      </el-table-column>
      <el-table-column prop="shipmentDate" :label="$t('valueTime')" width="120" align="center"></el-table-column>
      <el-table-column
        prop="promisedPaymentDate"
        :label="$t('deadlineForRepayment')"
        width="110"
        align="center"
      ></el-table-column>
      <el-table-column prop="investAmount" :label="$t('raisedFunds')" width="120" align="center">
        <template v-slot="scope">
          {{scope.row.investAmount | toThousandFilterTwo}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('projectInformation')" width="100" align="center">
        <template v-slot="scope">
          <el-button size="small" class="btn-black" @click="dateils(scope)">
            {{
            $t("see")
            }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <AdPagination
      :currentPage="tablePageCP"
      :pageSize="tablePagePS"
      :pageTotal="tableTotal"
      @handlePage="handlePage"
    ></AdPagination>
  </div>
</template>

<script>
// import { connect, getFinanceRequests } from '@/utils/blockchain';
// import { apiGetFinanceRequests } from "@/utils/api";
import AdPagination from "@/components/ad-pagination";
import filter from "@/filters";
export default {
  components: {
    AdPagination
  },
  data() {
    return {
      isClick: true,
      form: {
        name: ''
      },
      primaryData: [],
      status: '',
      tableData: [],
      tableTotal: 0,
      tablePageCP: 1,
      tablePagePS: 10,
      searchFinac: this.$enums.FINANCSTATUS,
      tableLoading: false,
      tableEmpty: this.$t('loading'),
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    for (let sf of this.searchFinac) {
      sf.select = false;
    }
    this.searchFinac[0].select = true;
    this.status = 0;
    this.tableLoading = true;
  },
  methods: {
    // 获取列表信息
    getList() {
      this.tableLoading = true;
      const data = {
        status: this.status,
        page: this.tablePageCP,
        pageSize: this.tablePagePS
      };
      if (!this.status) {
        delete data.status;
      }
      this.$axios.get('/v1/supplier/financ-orders', { params: data }).then((response) => {
        if (response.code === 0 || response.code === '0') {
          this.tableData = response.data.rows || [];
          this.tableTotal = response.data.count || 0;
          this.tableLoading = false;
          this.isClick = false;
          this.tableEmpty = this.$t('tableEmpty');
          this.tableData.map((item) => {
            item.statusText = filter.financingStageStatus((item.status).toString());
            item.receivablesOrder.businessType = filter.filterBusinessType(item.receivablesOrder.businessType);
            item.financingAmount = Number(item.financingAmount) / this.$enums.UNIT_MILLION;
            item.investAmount = Number(item.investAmount) / this.$enums.UNIT_MILLION;
          });
        } else {
          this.tableLoading = false;
          this.isClick = false;
          this.tableEmpty = this.$t('tableEmpty');
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => {
        this.tableLoading = false;
        this.isClick = false;
        this.tableEmpty = this.$t('tableEmpty');
      });
    },
    handlePage(val) {
      this.tablePageCP = val.currentPage;
      this.tablePagePS = val.pageSize;
      this.getList();
    },
    dateils(scope) {
      this.$router.push({ path: '/financing-list/finance-info', query: { id: scope.row.id } });
    },
    typeClick(item, index) {
      this.tablePageCP = 1;
      this.tableTotal = 0;
      this.searchFinac.forEach(item => {
        item.select = false;
      });
      this.searchFinac[index].select = true;
      this.status = index;
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
.el-table th {
  background: #81d8d0;
  color: white;
}
.order-num {
  width: 200px;
  margin-right: 20px;
}
.btn-list {
  margin-bottom: 20px;
}
.search-date {
  width: 450px;
}
</style>
